$(function (){
  const $modal = $(".js-modal");
  const speed = 500;

  $(".js-open-term").on('click', (e) => {
    $modal.fadeIn(speed);
  });

  $(".js-close-modal, .js-modal").on('click', (e) => {
    if ($(e.target).hasClass('js-close-modal') || $(e.target).hasClass('js-modal')) {
      $modal.fadeOut(speed);
    }
  });
})
